@import "@aws-amplify/ui-react/styles.css";

/* @import "@fontsource/barlow"; */
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;500&display=swap');



body {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
}

:root {
  /* --amplify-fonts-default-variable: "Barlow";
  --amplify-fonts-default-static: "Barlow"; */
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

.amplify-button--primary {
  border-width: var(--amplify-components-button-primary-border-width);
  background-color: #00449E;
  border-color: var(--amplify-components-button-primary-border-color);
  color: var(--amplify-components-button-primary-color);

  --amplify-internal-button-disabled-border-color: var(
    --amplify-components-button-primary-disabled-border-color
  );
  --amplify-internal-button-disabled-background-color: var(
    --amplify-components-button-primary-disabled-background-color
  );
  --amplify-internal-button-disabled-color: var(
    --amplify-components-button-primary-disabled-color
  );
  --amplify-internal-button-loading-background-color: var(
    --amplify-components-button-primary-loading-background-color
  );
  --amplify-internal-button-loading-border-color: var(
    --amplify-components-button-primary-loading-border-color
  );
  --amplify-internal-button-loading-color: var(
    --amplify-components-button-primary-loading-color
  );
}

.amplify-button--primary:hover {
  background-color: #085ccd;
  border-color: var(--amplify-components-button-primary-hover-border-color);
  color: var(--amplify-components-button-primary-hover-color);
}

.amplify-link {
  color: #042B60;
  -webkit-text-decoration: var(--amplify-components-link-text-decoration);
          text-decoration: var(--amplify-components-link-text-decoration);
  cursor: pointer;
}

.amplify-link:hover {
  color: #085ccd;
  -webkit-text-decoration: var(--amplify-components-link-hover-text-decoration);
          text-decoration: var(--amplify-components-link-hover-text-decoration);
}

[data-amplify-authenticator] [data-amplify-router] {
  /* background-color: var(--amplify-components-authenticator-router-background-color); */
  background-color: #ffffff;
  box-shadow: 1px 1px 5px;
  /* border-color: #0048E0; */
  /* border-width: 7px; */
  /* border-width: var(--amplify-components-authenticator-router-border-width); */
  border-style: var(--amplify-components-authenticator-router-border-style);
  border-radius: 30px;
}

.amplify-label {
  --amplify-visually-hidden: show;
  display: flex;
  color: rgb(0, 0, 0);
}
/* 
.amplify-input {
  color: hsl(0deg 0% 100%);
} */

/* .amplify-input {
  color: #ffffff;
} */

.amplify-field-group__field-wrapper {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}

.amplify-field-group__outer-end {
  background-color: #ffffff;
}

